.header-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  
  .header-mobile {
    width: 100%;
    
    .navbar-area {
      position: relative;
      background-color: transparent;
      border-bottom: none;
    }
  }
  
  .logo {
    padding: 2rem 0;
  }
  
  .nav-app {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding: 0.5rem 0;
    color: #fff;
    min-width: 80%;
    
    .languages{
      display: flex;
      gap: 0.5rem;
      
      a {
        cursor: pointer;
        
        &.selected {
          font-weight: bold;
          
          &:hover {
            color: white;
          }
        }
        
        &:hover {
          color: var(--paragraph-color);
        }
      }
    }
    
    .main-menu {
      display: flex;
      gap: 2rem;
      text-transform: uppercase;
      font-weight: 500;
      
      .item:hover {
        color: var(--paragraph-color);
      }
    }
  }
  
  .vertical-links {
    display: flex;
    flex-direction: column;
    color: white;
    
    .item {
      padding-bottom: 3px;
      border-bottom: 1px solid white;
      
      &.no-border-bottom {
        border-bottom: 0;
      }
      
      &:hover {
        color: var(--paragraph-color);
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .header-home {
    border-bottom: 1px solid #fff;
    
    .logo {
      padding: 0;
    }
    
    .nav-app {
      display: none;
    }
  }
}

@media screen and (min-width: 560px) and (max-width: 850px) {
  .header-home {
    border-bottom: 1px solid #fff;
    
    .logo {
      padding: 0;
    }
    
    .nav-app {
      display: none;
    }
  }
}