.search-page-wrap {
  background-color: var(--light-bg-color);
  
  .actions-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    border-top: 1px solid #DCC6BA;
    border-bottom: 1px solid #DCC6BA;
    padding: 0.5rem 0;
    
    .pagination-component {
      justify-content: center;
    }
    
    .buttons-actions {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
    }
    
    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
  
      .icon-button {
        border: 1px solid var(--main-color-three);
        border-radius: 5px;
        background-color: white;
        height: 48px;
        margin-left: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
  
        &.disabled {
          cursor: default;
          opacity: 0.6;
        }
  
        svg {
          height: 48px;
          width: 48px;
        }
      }
    }
    
    .select-filter-properties {
      width: 100%;
      padding-left: 1rem;
    }
  }
  
  .pagination-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
  }
}
