.cover-app {
  z-index: 10 !important;
  
  .cover-inner-wrap {
    padding-bottom: 30px;
  }
  
  .content-cover {
    width: 100%;
  }
  
  .title {
    text-align: center;
    color: #fff;
    margin: 8rem 0 6rem 0;
    padding: 0 1rem;
  }
  
  .search-filters-container {
    background-color: rgb(229 239 244 / 92%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem 0;
  }
}