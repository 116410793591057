.not-found-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-top: 13rem;
  
  .number {
    font-size: 10rem;
    margin: -2rem 0;
  }
  
  .text {
    font-size: 1.5rem;
    font-weight: 500;
  }
  
  .message {
    text-align: center;
    font-size: 16px;
  }
}