.home {
  section {
    padding: 4.5rem 0;
  }
  
  .properties-section {
    .container {
      display: flex;
      flex-direction: column;
      gap: 4rem;
    }
  }
}