.call-to-action-subscribe {
  // background-color: var(--main-color-two);
  background-color: #eaded6;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem;
  
  .description {
    font-size: 22px;
    text-align: center;
  }
  
  button {
    width: auto;
  }
}