.title-underline {
  font-weight: normal;
  border-bottom: 4px solid var(--main-color-two);
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-size: 28px;
}

.properties-grid {
  .property-card {
    margin-bottom: 30px;
  }
}

.cta-content-flex {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 4rem;

  .title {
    color: white;
    text-align: center;
  }
}

.page-wrapper {
  background-color: var(--light-bg-color);
}

.page-content {
  padding-top: 15rem;
}

.properties-analyze-screen {
  .results-container {
    padding: 3rem;
    
    .actions {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
    }
    .links {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      max-width: 10rem;
    }
  }
  
  .authorization-container {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
  }
}



@media screen and (max-width: 550px) {
  .page-wrapper {
    .page-content {
      padding-top: 8rem;
    }

    .buttons-actions {
      justify-content: center !important;
    }

    footer .rld-single-input {
      align-items: center;
    }
  }
}

@media screen and (min-width: 560px) and (max-width: 850px) {
  .page-wrapper {
    .page-content {
      padding-top: 8rem;
    }

    .buttons-actions {
      justify-content: center !important;
    }

    footer .rld-single-input {
      align-items: center;
    }
  }
}

@media screen and (min-width: 851px) and (max-width: 991px) {
  .page-wrapper {
    .page-content {
      padding-top: 8rem;
    }
  }
}
