.property-details-area {
  .property-title {
    padding: 1rem 0;
    border-top: 1px solid #DCC6BA;
    border-bottom: 1px solid #DCC6BA;
    text-align: center;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 575px) {
  .property-details-slider-info {
    display: flex !important;
    flex-direction: column !important;
    text-align: center;
  }
}