/*----------------------------------------------
    ## All section style
----------------------------------------------*/
.city-intro-area {
  background-color: rgba(216, 198, 187, 0.302);
  position: relative;
  .line-top {
    position: absolute;
    top: 200px;
    left: -30px;
  }
  .line-bottom {
    position: absolute;
    bottom: 50px;
  }
}
.follow-dream-area {
  .thumb {
    border-radius: 10px;
    overflow: hidden;
    margin-right: 30px;
  }
}

/*----------------------------------------------
    ## search page 
----------------------------------------------*/
.search-page-area {
  overflow: hidden;
}
.search-page-search-wrap {
  width: 45%;
  float: left;
  padding-right: 30px;
  position: fixed;
  z-index: 0;
  #gmap {
    height: 100vh;
    margin-top: 90px;
  }
}
.search-page-right-wrap {
  width: 55%;
  float: right;
}
.rld-search-page-tab {
  border-bottom: 0;
  .nav-item {
    margin-bottom: 0;
    a {
      border: 0;
      padding: 0;
      text-align: center;
      border-radius: 5px;
      height: 48px;
      width: 48px;
      line-height: 52px;
      margin-right: 8px;
      position: relative;
      display: block;
      background: #ffeee2;
      color: var(--main-color-one);
      font-family: var(--heading-font);
      font-weight: 600;
      @include transition(all, 0.4s, ease);
      font-size: 18px;
      &:hover,
      &:focus,
      &.active {
        background: var(--main-color-one);
        color: $color-white;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.search-page-right-side {
  margin-right: 50px;
  padding-top: 135px;
  .section-title .title {
    font-size: 24px;
  }
  .rld-main-search {
    padding: 20px 25px 4px 25px;
    margin-bottom: 30px;
    border-radius: 6px;
    .colx {
      padding: 0 10px;
      display: inline-block;
    }
    .col1 {
      width: 30%;
    }
    .col2 {
      width: 19%;
    }
    .col3 {
      width: 17%;
    }
  }
}

.single-feature .thumb {
  height: 200px;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.search-page-map {
  .single-feature {
    margin-bottom: 0;
  }
}

.gm-style .gm-style-iw-c {
  padding: 16px;
}
.gm-ui-hover-effect {
  background: var(--main-color-one) !important;
  top: 0 !important;
  right: 0 !important;
  width: 30px !important;
  height: 30px !important;
  z-index: 9999 !important;
  border-radius: 0 10px 0 10px !important;
  opacity: 1 !important;
}

/*----------------------------------------------
    ## single popular-post
----------------------------------------------*/
.post-and-search {
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  padding: 25px 50px 40px 170px;
  position: relative;
  overflow: hidden;
  margin-top: -74px;
  background: $color-white;
  border-radius: 10px;
}
.news-search-btn {
  position: absolute;
  left: 52px;
  top: 50%;
  margin-top: -15px;
  cursor: pointer;
  i {
    font-size: 30px;
    color: var(--main-color-one);
  }
}
.news-search-box {
  position: absolute;
  width: 0;
  left: -10px;
  top: 0;
  height: 100%;
  background: $color-white;
  display: flex;
  align-items: center;
  z-index: 3;
  @include transition(all, 0.7s, ease);
  input {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border: 0 !important;
    border-right: 1px solid var(--main-color-one);
    padding: 0 30px;
    margin-right: 70px;
    font-size: 18px;
    color: rgba(16, 20, 37, 0.5);
    font-weight: 600;
    &::placeholder {
      color: rgba(16, 20, 37, 0.5);
      font-weight: 600;
    }
  }
  button {
    font-size: 30px;
    color: var(--main-color-one);
    position: absolute;
    right: 28px;
    top: 50%;
    margin-top: -28px;
    background: 0;
    border: 0;
    cursor: pointer;
  }
  &.news-search-box-show {
    width: 100%;
    left: 0;
  }
}
.popular-post-slider {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: -50px;
    bottom: 8px;
    height: 100%;
    width: 1px;
    background: var(--main-color-one);
  }
}
.single-popular-post {
  .media-left {
    margin-right: 15px;
    img {
      border-radius: 4px;
    }
  }
  .media-body {
    h6 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
      @include transition(all, 0.5s, ease);
    }
    span {
      font-size: 12px;
    }
  }
  &:hover .media-body h6 {
    color: var(--main-color-one);
    text-decoration: underline;
  }
}

/*----------------------------------------------
    ## service-slider-2
----------------------------------------------*/
.service-area-about {
  background-repeat: no-repeat;
  background-position: right;
  background-size: 44% 100%;
}
.service-slider-2 {
  .single-intro {
    padding: 0 30px 35px 30px;
    margin: 0 15px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 29px;
      bottom: 0;
      width: 100%;
      height: 88%;
      box-shadow: $box-shadow;
      z-index: -1;
      background: $color-white;
    }
    .thumb {
      height: 60px;
      width: 60px;
      line-height: 58px;
    }
  }
}

/*----------------------------------------------
    ## news-details-author-social
----------------------------------------------*/
.news-details-author-social {
  margin-top: -30px;
  .author {
    display: flex;
    img {
      border-radius: 6px;
      height: 60px;
      width: 60px;
    }
    p {
      align-self: flex-end;
      margin-bottom: 0;
      margin-left: 15px;
      font-size: 13px;
    }
  }
}

/*----------------------------------------------
    ## news-details-wrap
----------------------------------------------*/
.news-details-wrap {
  p {
    margin-bottom: 23px;
  }
  .title1 {
    margin-bottom: 20px;
  }
  .title2 {
    margin-bottom: 25px;
    font-size: 18px;
  }
  .news-details-thumb {
    padding-top: 10px;
    margin-bottom: 35px;
  }
}

/*----------------------------------------------
    ## contact page
----------------------------------------------*/
.contact-form-wrap {
  background: $color-white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: $box-shadow;
  .rld-single-input {
    margin-top: 10px;
  }
  button {
    margin-top: 23px;
    padding: 0 45px;
    letter-spacing: 0.5px;
  }
  .social-icon {
    margin-top: 20px;
  }
}
.contact-form-bg {
  input,
  textarea {
    background: #f3f3f3;
  }
}
.contact-page-map {
  line-height: 1;
  iframe {
    height: 474px;
  }
}
.single-contact-info {
  margin-bottom: 30px;
  p {
    i {
      margin-right: 10px;
      color: var(--main-color-one);
      font-size: 18px;
    }
  }
}
.register-page-area {
  margin-top: -50px;
}
.rld-comment-form {
  .single-page-small-title {
    margin-bottom: 22px;
  }
  .rld-single-input {
    margin-bottom: 20px;
  }
}

/*----------------------------------------------
    ## property-details-slider
----------------------------------------------*/
.property-details-slider {
  margin-bottom: 40px;
  .owl-prev {
    position: absolute;
    right: 90px;
    bottom: 25px;
    border: 1px solid $color-white;
    color: $color-white;
  }
  .owl-next {
    position: absolute;
    right: 25px;
    bottom: 25px;
    border: 1px solid $color-white;
    color: $color-white;
  }
}
.property-details-slider-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    font-size: 28px;
  }
  .price {
    white-space: nowrap;
    font-size: 18px;
    // margin-left: 40px;
    font-weight: 600;
  }
}
.property-details-slider-numbers {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  margin-top: 40px;
  padding: 30px 0px;

  .col-md-3 {
    display: flex;
    justify-content: center;
  }
}
.single-property-info {
  &.centered {
    text-align: center;

    h5 {
      font-size: 22px;
    }
  }
  h5 {
    font-weight: 600;
  }
  p {
    svg {
      margin-right: 10px;
      color: var(--main-color-one);
    }
    img {
      margin-right: 11px;
    }
  }
}
.property-news-single-card {
  padding-bottom: 50px;
  margin-bottom: 40px;
  h4 {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 26px;
  }
  .feature-icons {
    li {
      font-size: 21px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 20px;
      }
    }
  }
  p,
  .description {
    margin-bottom: 20px;
    font-size: 20px;
    white-space: pre-wrap;
  }
  a {
    font-weight: 600;
    color: var(--main-color-one);
    line-height: 1;
  }
  iframe {
    width: 100%;
    height: 330px;
  }
  &.style-two {
    padding-bottom: 70px;
  }
  .share-icons {
    display: flex;
    align-items: center;
    padding-bottom: 30px;

    h4 {
      margin: 0px 100px 0px 0px;
    }

    .icons-list {
      display: flex;
      align-items: center;

      .share-icon {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        text-align: center;
        line-height: 49px;
        color: var(--main-color-one);
        background: var(--main-color-two);
        transition: all 0.4s ease;
        border: none;
        font-size: 26px;
        display: block;
        margin-right: 30px;
      }
    }
  }
}

/*----------------------------------------------
    ## error page
----------------------------------------------*/
.error-page {
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: linear-gradient(#fff4ef, #fff);
  a {
    color: var(--main-color-one);
    @include transition(all, 0.4s, ease);
    font-size: 18px;
    position: absolute;
    left: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
  h2 {
    font-size: 300px;
    font-weight: 700;
    color: #5b6880;
    background: transparent;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--main-color-one);
  }
  .error-page-wrap {
    display: inline-block;
    position: relative;
  }
}

/*----------------------------------------------
    ## add-new-property-area
----------------------------------------------*/
.add-new-property-area {
  .rld-single-select .single-select {
    padding: 0 35px 0 10px;
    border: 0 !important;
    border-bottom: 1px solid #d4d4d4 !important;
    border-radius: 0;
  }
  .rld-single-input textarea {
    padding: 10px;
    border: 0 !important;
    border-bottom: 1px solid #d4d4d4 !important;
    border-radius: 0;
  }
}
.add-property-btn-wrap {
  .btn {
    height: 44px;
    line-height: 44px;
    padding: 0 20px;
  }
}

/*----------------------------------------------
    ## add-new-property-area
----------------------------------------------*/
.why-choose-us {
  .container {
    border: 10px solid var(--main-color-one);
    border-radius: 20px;
    background: var(--light-bg-color);
    .why-item {
      margin: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img-container {
        position: relative;
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            0deg,
            rgba(188, 160, 148, 0.7) 20%,
            rgba(188, 160, 148, 0.01) 100%
          );
        }
      }
      button {
        margin-top: -24px;
        padding: 0px 50px;
        margin-bottom: 30px;
        border: 2px solid white;
      }
    }
  }
}

.contact-area {
  a:hover {
    transition: all 0.3s ease 0s;

    h5 {
      color: inherit !important;
    }
  }
}

.unsubscribe-message {
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
