.latest-properties {
  .title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;  
    border-bottom: 4px solid var(--main-color-two);
    margin-bottom: 2rem;
    
    .title {
      font-weight: normal;
      text-transform: uppercase;
      font-size: 28px;
    }
    
    .search-link {
      font-size: 18px;
      font-weight: 500;
      color: var(--main-color-one);
    }
  }
  
  .properties-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
    grid-gap: 2rem;
    gap: 2rem;
  }
}