/*--------------------------------------------------------------
	## Widgets
--------------------------------------------------------------*/
.sitebar {
  .widget {
    margin-bottom: 50px;
  }
}
.widget {
  border: 1px solid rgba(197, 197, 197, 0.5);
  border-radius: 8px;
}
.widget-title {
  font-weight: 600;
  margin-bottom: 16px;
  color: white;
}
.widget_nav_menu {
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      a {
        color: white;
        font-size: 14px;
        @include transition(all, 0.4s, ease);
        &:hover {
          color: var(--light-bg-color);
        }
      }
    }
  }
}
.widget-subscribe {
  .rld-single-input {
    margin-bottom: 10px;
  }
}

.widget-sidebar-search-wrap {
  padding: 30px;
  .btn-wrap .btn {
    margin-top: 40px;
  }
}
.widget-sidebar-search {
  float: left;
  .title {
    font-size: 18px;
    font-weight: 600;
    font-family: var(--heading-font);
    color: var(--heading-color);
    margin-bottom: 15px;
    line-height: 1;
  }
  .price {
    line-height: 1;
    padding-top: 5px;
  }
  .btn {
    height: 40px;
    line-height: 40px;
    padding: 0 18px;
  }
  .widget-sidebar-item-wrap {
    margin-bottom: 30px;
    display: block;
    float: left;
    width: 100%;
  }
  .rld-single-input.left-icon input,
  .rld-single-select .single-select {
    height: 40px;
    line-height: 40px;
  }
  .rld-single-input.left-icon:before {
    top: 14px;
    left: 17px;
    background-size: contain;
    height: 13px;
    width: 13px;
  }
  .rld-single-select .single-select:before {
    top: 16px;
    height: 9px;
    width: 9px;
    background-size: 100%;
  }
}

/********* widget-owner-info ********/
.widget-owner-info {
  // border: 1px solid var(--border-color);
  // border-radius: 8px;
  background-color: white;
  .owner-info {
    background-color: #d8c6bb;
    border-bottom: 1px solid var(--border-color);
    padding: 30px 15px 26px 15px;
    .thumb {
      display: inline-block;
      padding: 0px 40px;
      margin-bottom: 15px;
      img {
        border: 2px solid var(--border-color);
        border-radius: 50%;
      }
    }
    h6 {
      margin-bottom: 0;
      font-size: 20px;
    }
    .designation {
      font-size: 16px;
    }
    p {
      margin-bottom: 0;
      color: var(--main-color-one);
      span {
        color: var(--heading-color);
        margin: 0 6px;
      }
    }
  }
  .contact {
    background-color: #c7afa3;
    padding: 20px 30px 30px;
    border-bottom: 1px solid var(--border-color);
    h6 {
      font-size: 16px;
      text-align: center;
      color: white;
    }
    .rld-single-input {
      margin-bottom: 10px;
      input {
        background-color: #c7afa2;
        color: #fff;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid #fff;
        padding: 3px 0;
        height: 35px;
        line-height: 30px;
        font-size: 16px;
        
        &::placeholder {
          color: #fff;
        }
      }
    }
    
    .phone-country {
      input {
        width: inherit !important;
        padding-left: 48px !important;
      }
      .flag-dropdown {
        background-color: transparent;
        border: none;
        
        .arrow {
          border-top: 4px solid #fff;
        }
      }
    }

    .btn {
      padding: 0 15px;
      font-size: 16px;
      height: 35px;
      line-height: 35px;
    }
  }
  .contact-info {
    background-color: #bca094;
    padding: 20px 30px 20px;
    color: white;
    
    h6 {
      font-size: 16px;
      color: white;
    }
    
    .media {
      margin-bottom: 15px;
      .media-left {
        display: flex;
        margin-right: 10px;
        i {
          color: white;
        }
      }
      .media-body {
        font-size: 16px;
        line-height: 18px;
        p {
          font-weight: 600;
          font-family: var(--heading-font);
          color: var(--heading-color);
          margin-bottom: 0;
          line-height: 18px;
          color: white;
        }
        a {
          display: block;
        }
      }
    }
  }
  
  .text-required {
    color: white;
    margin: 5px 0;
  }
  
  .btn:disabled {
    opacity: 0.35;
  }
  
  form .btn {
    .spinner,
    i {
      margin-left: 5px;
    }
  }
}
