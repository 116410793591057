.fields-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .rld-single-input,
  .rld-radio-input {
    width: 100%;
  }
}
