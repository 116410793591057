.rld-main-search {
  input[type='text'] {
    line-height: 48px;
    font-size: 18px;
    padding: 0 15px;
    border: 1px solid #d4d4d4 !important;
    background: white !important;
    color: $text-color;
    font-weight: normal;

    &::placeholder {
      color: $text-color;
    }
  }
}

.rld-radio-input {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  background: #f3f3f3;
  border: 1px solid #d4d4d4;
  transition: all 0.4s ease;
  border-radius: 4px;
  font-size: 15px;
  padding: 20px;

  label {
    cursor: pointer;
    input {
      margin-right: 10px;
    }
  }
}
