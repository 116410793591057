.header-filters-app {
  background-color: var(--main-color-one);
  width: 100%;

  &.sticky-header {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
  }

  .header-mobile {
    display: none;
  }

  header {
    display: flex;
    display: flex;
    justify-content: space-between;
    min-width: 100%;
  }

  .logo {
    display: block;
    padding: 2rem 0 1rem 0;

    svg {
      max-height: 40px;
      min-height: 3rem;
    }
  }

  .nav-app {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
    padding: 0.5rem 0;
    color: #fff;
    min-width: 80%;

    .languages {
      display: flex;
      gap: 0.5rem;

      a {
        cursor: pointer;

        &.selected {
          font-weight: bold;
        }
      }
    }

    .main-menu {
      display: flex;
      gap: 2rem;
      text-transform: uppercase;
      font-weight: 500;

      .item:hover {
        color: var(--light-bg-color);
      }
    }
  }

  .filters-container {
    padding: 1rem 0;
    transition: transform 0.5s ease-in-out;

    .container {
      display: flex;
      justify-content: center;
    }
  }

  .vertical-links {
    display: flex;
    flex-direction: column;
    color: white;

    .item {
      padding-bottom: 3px;
      border-bottom: 1px solid white;

      &.no-border-bottom {
        border-bottom: 0;
      }

      &:hover {
        color: var(--paragraph-color);
      }
    }
  }

  .expand-filters {
    background-color: white;
  }

  .expand-filter-button {
    background-color: white;
    text-align: center;
    .toggle-btn {
      width: 100%;
      border-bottom: 1px solid var(--main-color-one);
      
      svg {
        margin-right: 5px;
      }
    }
  }
  
  button[aria-expanded="true"]{
    
    .fa-caret-down {
      display: none;
    }
  }
  
  button[aria-expanded="false"]{
    
    .fa-caret-up {
      display: none;
    }
  }
}

@media screen and (max-width: 550px) {
  .header-filters-app {
    border-bottom: 1px solid #fff;

    .header-app,
    .filters-container {
      display: none;
    }

    .header-mobile {
      display: inherit;

      .logo {
        padding: 0;
      }

      .navbar-area {
        position: relative;
      }
    }
  }
}

@media screen and (min-width: 550px) and (max-width: 850px) {
  .header-filters-app {
    border-bottom: 1px solid #fff;

    .header-app,
    .filters-container {
      display: none;
    }

    .header-mobile {
      display: inherit;

      .logo {
        padding: 0;
      }

      .navbar-area {
        position: relative;
      }
    }
  }
}

@media screen and (min-width: 851px) and (max-width: 991px) {
  .header-filters-app {
    border-bottom: 1px solid #fff;
    .header-app,
    .filters-container {
      display: none;
    }
    .header-mobile {
      display: inherit;
      .logo {
        padding: 0;
      }
      .navbar-area {
        position: relative;
      }
    }
  }
}
