.magazines-container {
  position: relative;
  
  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    width: 100%;
    height: 50%;
    border: 8px solid var(--main-color-one);
    opacity: 0.85;
  } 
  
  .magazines {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    gap: 6rem;
    flex-wrap: wrap;
    margin-top: 4rem;
    
    .magazine {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      
      img {
        height: 25rem;
      }
      
      p {
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
  
  .square {
    // position: absolute;
    // border: 3px solid red;
    // height: 50%;
    // width: 100%;
    // bottom: 20px;
    // z-index: 0;
  }
}