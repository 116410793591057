.comment-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  
  .image-wrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    
    img {
      object-fit: none;
      border-radius: 100%;
    }
  }
  
  .comment-details {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    
    .comment, .author {
      font-size: 21px;
    }
    
  }
}

@media screen and (max-width: 550px) {
  .comment-container {
    .image-wrapper, .comment-details {
      flex: auto;
    }
  }
}

@media screen and (min-width: 560px) and (max-width: 850px) {
  .comment-container {
    .image-wrapper, .comment-details {
      flex: auto;
    }
  }
}