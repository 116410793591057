.subscribe-alerts {
  .rld-single-input, .wrapper-input {
    margin-bottom: 10px;
  }
  
  .wrapper-input {
    .dropdown button {
      display: block;
    }
  }
  
  .confirm-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    
    .cancel {
      background-color: white;
      color: var(--paragraph-color);
      border: 1px solid var(--main-color-three);
    }
  }
}