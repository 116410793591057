.slider {
  .max-and-min {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;
    color: $text-color;
    margin-bottom: 20px;
  }
  .slider-container {
    margin: 20px 15px 20px 10px;

    .input-range__label.input-range__label--min,
    .input-range__label.input-range__label--max {
      display: none;
    }

    .input-range__track--active {
      background: var(--main-color-one);
      opacity: 0.5;
    }

    .input-range__slider {
      background: var(--main-color-one);
      border: none;
    }

    .input-range__label-container {
      color: $text-color;
      font-size: 13px;
      top: -3px;
      left: -6px !important;
      background: rgba(248, 244, 242, 0.9);
      padding: 2px 10px 2px;
      margin-left: -10px;
    }

    .input-range__label {
      font-family: var(--heading-font);
    }
  }
}
