/*--------------------------------------------------
    ## All responsive style
---------------------------------------------------*/
/********* home 1 *********/
.banner-inner .title {
  font-size: 55px;
}
.section-title .title {
  font-size: 40px;
}
.section-title.pd-left {
  padding-left: 20px;
}
.section-title .description {
  padding-top: 30px;
  text-align: left;
  font-size: 25px;
}
.banner-inner .btn {
  height: 48px;
  line-height: 48px;
}
.banner-inner .video-play-btn {
  height: 48px;
  width: 48px;
  line-height: 44px;
  font-size: 16px;
}
.single-city .details .title {
  font-size: 22px;
}
.single-city .details p {
  font-size: 13px;
}
.single-explore .details {
  padding: 23px 20px;
}
.single-explore .details .list li:first-child {
  margin-right: 10px;
}
.single-feature .details {
  padding: 20px 15px;
}
.single-feature .details .info-list {
  font-size: 12px;
  white-space: nowrap;
}
.single-feature .details .info-list i {
  font-size: 14px;
}
.single-feature .details .contact-list {
  padding: 10px 15px 14px 15px;
}
.single-feature .details .contact-list li {
  margin-right: 3px;
}

/******** home 3 ********/
.follow-dream-area .thumb {
  margin-right: 0;
}

/********* home 4 **********/
.apartments-slider .thumb .title {
  margin-left: -90px;
  font-size: 35px;
}
.apartments-slider .title {
  font-size: 35px;
}
.apartments-slider-2 .details h2 {
  font-size: 30px;
}

/******** search page ********/
.search-page-right-side {
  margin-right: 30px;
}
.search-page-right-side .rld-main-search .col1 {
  width: 60%;
}
.search-page-right-side .rld-main-search .col2 {
  width: 40%;
}
.search-page-right-side .rld-main-search .col3 {
  width: 33.33%;
}
.single-feature .thumb {
  height: 200px;
}

@media only screen and (max-width: 1100px) {
  .section-title .sub-title {
    font-size: 16px;
  }
  .section-title .title {
    font-size: 30px;
  }
  .section-title .description {
    font-size: 22px;
  }
  .section-title.pd-left {
    padding-left: 0;
  }
  .single-feature .details .feature-logo {
    height: 45px;
    width: 45px;
    line-height: 45px;
    right: 15px;
    top: -23px;
  }
  .single-feature .details .feature-logo img {
    width: 15px;
  }
  .single-intro-media {
    padding: 30px;
  }
  .single-intro-media .media-body {
    padding-left: 16px;
  }
  .single-intro-media .media-body h4 {
    font-size: 24px;
  }
  .single-intro-media .media-body p {
    font-size: 18px;
  }
  .footer-area .widget-title {
    font-size: 20px;
  }
  .call-to-action.style-two .cta-content {
    text-align: center;
  }
  .call-to-action.style-two .cta-content .title {
    margin-bottom: 20px;
    font-size: 28px;
    display: block;
  }
  .call-to-action.style-two .cta-content .title,
  .call-to-action.style-two .cta-content .btn {
    float: none;
  }
  .single-intro .details p br {
    display: none;
  }
  .service-slider-2 .single-intro {
    padding: 0 15px 35px 15px;
  }
  .service-slider-2 .single-intro .details .title {
    font-size: 20px;
  }
  .service-area-about {
    background: none !important;
  }
  .banner-inner .title {
    font-size: 48px;
  }
  .banner-inner .sub-title {
    margin-bottom: 15px;
  }
  .h1-service-slider-area {
    margin-top: 80px;
  }
  .banner-inner-wrap {
    padding-top: 80px;
  }
  .subscribe-area h2 {
    font-size: 30px;
  }
  .service-slider.slick-slider .slick-active ~ .slick-active ~ .slick-active .single-service {
    margin: 10px 5px 10px 15px !important;
  }

  /***** home 3 *****/
  .client-review-img .clr-img2 {
    left: 100px;
  }

  /***** home 4 *****/
  .floor-plan-area.pd-bottom-90 {
    padding-bottom: 60px;
  }

  /****** pricing *******/
  .single-pricing .details .title {
    font-size: 18px;
  }
  .single-pricing .details .price {
    font-size: 24px;
  }
  .single-pricing .details ul li {
    font-size: 14px;
  }

  .service-slider-2 {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .rld-1-3-bg {
    padding: 0;
    background: none;
    background-image: none !important;
  }
  .section-title .inner-title {
    font-size: 26px !important;
  }
  .section-title .inner-title br {
    display: none;
  }
  .section-title .description {
    font-size: 18px;
    padding: 30px;
  }
  .navbar-area.style-two {
    height: 70px;
  }
  .banner-inner .sub-title {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .banner-inner .title {
    font-size: 40px;
  }
  .banner-inner-wrap {
    height: auto;
    padding: 170px 0 100px 0;
  }
  .h1-service-slider-area {
    margin-top: 80px;
  }
  .single-leading-feature .thumb img {
    width: 100%;
  }
  .single-feature .thumb img {
    height: 100%;
    max-width: none;
  }
  .call-to-action .cta-content {
    text-align: center;
  }
  .call-to-action .cta-content .title {
    margin-bottom: 20px;
    font-size: 28px;
    display: block;
  }
  .call-to-action .cta-content .title,
  .call-to-action .cta-content .btn {
    float: none;
  }
  .single-city {
    margin-top: 0 !important;
  }
  .single-intro-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .single-intro-media .media-left {
    margin-bottom: 20px;
    margin-left: 0 !important;
  }
  .single-intro-media .media-body {
    padding-left: 0;
    margin-bottom: 20px;
  }
  .single-intro .text {
    font-size: 40px;
  }
  .section-title p br {
    display: none;
  }

  /***** home 3 *****/
  .client-review-img .clr-img2 {
    left: 20px;
    top: 60%;
  }

  /********* home 4 **********/
  .apartments-slider .thumb .title {
    display: none;
  }
  .apartments-slider .thumb img,
  .apartments-slider .thumb:before {
    border-radius: 10px 10px 0 0;
  }
  .apartments-slider .details {
    padding: 20px 20px 30px;
    background: #f6f7fb;
    border-radius: 0 0 10px 10px;
  }
  .apartments-slider .title {
    font-size: 30px;
  }
  .apartments-slider .details h4 {
    font-size: 16px;
  }
  .apartments-slider .details .title {
    display: block;
  }
  .apartments-slider .details h3 {
    font-size: 22px;
  }
  .apartments-slider .details .sub-title {
    margin-bottom: 2px;
    font-size: 16px;
  }
  .apartments-slider .owl-controls {
    margin: inherit;
    margin-top: 25px !important;
  }
  .apartments-slider-2 .thumb img {
    border-radius: 10px 10px 0 0;
    width: 100%;
  }
  .apartments-slider-2 .align-self-end {
    margin-bottom: 0;
    margin-left: 0;
  }
  .apartments-slider-2 .details {
    border-radius: 0 0 10px 10px;
  }
  .apartments-slider-2 .item {
    margin-bottom: 15px;
  }
  .ap2-slider-controls .ap2-list-progress {
    width: 100%;
  }
  .rld-control-nav {
    margin-left: 24px;
    margin-top: 45px;
  }
  .floor-plan-area.pd-bottom-90 {
    padding-bottom: 90px;
  }

  /***** search page ******/
  .search-container {
    padding: 0 15px;
  }
  .search-page-right-side {
    padding-top: 40px;
    margin-right: 0;
  }
  .search-page-search-wrap {
    width: 100%;
    float: left;
    padding-right: 0;
    position: relative;
    z-index: 0;
  }
  .search-page-search-wrap #gmap {
    height: 450px;
    margin-top: -30px;
    margin-top: 0;
  }
  .search-page-right-wrap {
    width: 100%;
    float: right;
  }
  .shape-image-list.left-top {
    padding: 20px 0 0 20px;
  }
  .banner-search {
    margin-top: 60px;
  }
  .why-choose-us {
    margin-left: 10px;
    margin-right: 10px;

    h3 {
      font-size: 24px;
    }
  }
  .property-news-single-card .share-icons {
    flex-direction: column;
    align-items: flex-start;
    h4 {
      margin-bottom: 40px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .section-title .title {
    font-size: 28px;
  }
  .section-title .inner-title {
    font-size: 22px !important;
  }
  .banner-inner .title {
    font-size: 35px;
  }
  .single-explore .details {
    padding: 23px 15px;
  }
  .call-to-action .cta-content {
    padding: 40px 20px;
  }
  .call-to-action .cta-content .title {
    font-size: 25px;
  }
  .service-slider.slick-slider .slick-active ~ .slick-active .single-service {
    margin: 10px 7px 10px 15px !important;
  }

  /***** home 3 *****/
  .client-review-img .clr-img {
    display: none;
  }
  .single-team .thumb img {
    width: 100%;
  }
  .follow-dream-area .section-title {
    margin-bottom: 30px;
  }
  .single-follow-dream .media-left {
    margin-right: 18px;
    margin-top: 3px;
  }
  .single-follow-dream .media-body h4 {
    font-size: 16px;
  }
  .single-follow-dream .media-body {
    font-size: 14px;
  }
  .footer-area.style-two {
    margin-top: 300px;
  }
  .subscribe-area {
    padding: 40px 20px 50px 20px;
    margin-top: -260px;
  }
  .subscribe-area h2 {
    margin-bottom: 20px;
    font-size: 28px;
  }
  .subscribe-area p {
    margin-bottom: 23px;
  }

  /****** news page ********/
  .single-news .details {
    padding: 25px 18px 30px;
  }
  .single-news .details h4 {
    font-size: 18px;
  }
  .single-news .details .author img {
    margin-right: 10px;
  }
  .single-news .details .author span {
    font-size: 13px;
  }
  .post-and-search {
    padding: 15px 20px 20px 80px;
  }
  .news-search-btn {
    left: 20px;
    margin-top: -12px;
  }
  .news-search-btn i {
    font-size: 20px;
  }
  .news-search-box {
    left: -15px;
  }
  .news-search-box input {
    padding: 0 16px;
    margin-right: 40px;
    font-size: 15px;
    font-weight: 500;
  }
  .news-search-box button {
    font-size: 18px;
    right: 16px;
    margin-top: -17px;
  }
  .popular-post-slider:before {
    left: -20px;
  }
  .single-contact-info h5 {
    font-size: 16px;
  }

  .breadcrumb-area {
    padding: 150px 0 90px;
  }
  .breadcrumb-area .page-title {
    font-size: 35px;
  }
  .error-page h2 {
    font-size: 150px;
  }
  .property-filter-menu button {
    height: 45px;
    padding: 0 18px;
    line-height: 45px;
    font-size: 15px;
  }
  .subscribe-area .rld-single-input {
    flex-direction: column;
    input {
      margin-bottom: 20px;
    }
  }
  .property-details-slider-numbers {
    .col-md-3 {
      justify-content: center !important;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 575px) {
  .section-title {
    margin-bottom: 18px;
  }
  .section-title .inner-title {
    font-size: 20px !important;
  }
  .rld-banner-tab .nav-item a {
    height: 48px;
    line-height: 48px;
    padding: 0 25px;
    margin-right: 6px;
    font-weight: 500;
  }
  .slider-control-top {
    margin-top: 100px;
  }
  .slider-control-top .owl-prev {
    left: 0;
    top: -85px;
  }
  .slider-control-top .owl-next {
    left: 58px;
    top: -85px;
    padding-left: 2px;
  }
  .owl-controls .owl-prev {
    height: 45px;
    width: 45px;
    line-height: 41px;
    font-size: 24px;
  }
  .owl-controls .owl-next {
    height: 45px;
    width: 45px;
    line-height: 41px;
    font-size: 24px;
  }
  .back-to-top {
    width: 45px;
    height: 45px;
    line-height: 42px;
    font-size: 24px;
    right: 15px;
    bottom: 25px;
  }
  .section-title .btn-view-all {
    position: relative;
  }
  .single-leading-feature .details {
    padding: 15px;
  }
  .single-leading-feature .details .title,
  .single-leading-feature .details .price {
    font-size: 16px;
  }
  .single-leading-feature .details span {
    font-size: 13px;
  }
  .footer-logo {
    display: block;
    margin-bottom: 28px;
  }
  .main-search-area {
    margin-top: -48px;
  }
  .service-slider.slick-slider .slick-active .single-service {
    margin: 10px 7px 10px 7px;
  }

  /***** home 3 *****/
  .banner-inner .content br {
    display: none;
  }
  .single-team .thumb img {
    width: 100%;
  }
  .subscribe-area {
    padding: 40px 15px 50px 15px;
  }

  /***** home 4 *****/
  .gallery-thumb img {
    width: 100%;
  }
  .apartments-slider .title {
    font-size: 25px;
  }
  .apartments-slider-2 .details {
    padding: 20px 20px 30px 20px;
  }

  /****** search page ********/
  .search-page-search-wrap #gmap {
    height: 380px;
  }
  .search-page-right-side {
    margin-right: 0;
  }
  .search-page-right-side .rld-main-search .col1 {
    width: 100%;
  }
  .search-page-right-side .rld-main-search .col2 {
    width: 100%;
  }
  .search-page-right-side .rld-main-search .col3 {
    width: 100%;
  }
  .rld-search-page-tab {
    margin-bottom: 30px;
  }
  .search-page-right-side .rld-main-search {
    padding: 20px 25px 20px 25px;
  }
  .single-feature.style-two .thumb {
    flex: 0 0 100%;
    min-height: 230px;
  }
  .single-feature.style-two .thumb img {
    border-radius: 0;
  }
  .single-feature.style-two .details {
    flex: 0 0 100%;
    padding: 20px;
    display: block;
  }
  .single-feature.style-two .details .feature-logo {
    right: 20px;
    top: -23px;
    left: auto;
  }
  .error-page h2 {
    font-size: 130px;
  }
  .single-accordion {
    padding: 15px 18px;
  }
  .single-accordion .card-header h2 button {
    font-size: 16px;
  }
  .news-details-wrap .title1 {
    font-size: 26px;
  }
  .single-property-info {
    margin-bottom: 20px;
  }
  .gm-style-iw-d,
  .gm-style-iw.gm-style-iw-c {
    max-width: 290px !important;
  }
  .gm-style-iw.gm-style-iw-c .single-feature {
    margin-bottom: 0;
  }
  .single-feature .thumb {
    height: 300px;
  }
  .call-to-action.style-two .cta-content .d-flex {
    flex-direction: column;
    a {
      margin-right: 0 !important;
    }
  }
}

@media only screen and (max-width: 376px) {
  .subscribe-area .rld-single-input {
    padding-right: 0;
  }
  .subscribe-area button {
    position: relative;
    width: 100%;
    margin-top: 10px;
  }
  .post-and-search .popular-post-title {
    font-size: 13px;
  }
  .single-popular-post .media-left {
    margin-right: 10px;
  }
  .single-popular-post .media-left img {
    width: 45px;
  }
  .single-popular-post .media-body h6 {
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
  }
  .single-popular-post .media-body span {
    font-size: 10px;
    line-height: 10px;
  }
}

@media only screen and (max-width: 320px) {
  .nav-right-part .btn {
    height: 36px;
    line-height: 36px !important;
    padding: 0 8px 0 12px;
    font-size: 10px;
  }
  .nav-right-part .btn .right {
    padding-left: 1px;
    font-size: 10px;
  }
}
