.authorization-form {
  display: flex;
  flex-direction: column;
  width: 15rem;
  
  label {
    font-weight: 500;
  }
  
  button {
    margin-top: 1rem;
  }
  
  .error-code {
    margin-top: 0.5rem;
  }
}