.toggle-icon-button {
  border: 1px solid var(--main-color-three);
  background: white;
  color: $text-color;
  font-weight: normal;
  padding: 0 15px;
  width: 100%;

  svg:last-child {
    margin-left: 20px;
  }
}

.dropdown-item {
  color: $text-color;
}

.dropdown-menu {
  .form-control {
    font-size: 18px;
  }
  .check {
    font-size: 18px;
    cursor: pointer;
    color: $text-color;

    label {
      color: $text-color;
      cursor: pointer;
      margin-bottom: 0;
      margin-left: 10px;
      width: 100%;
    }
  }
}
