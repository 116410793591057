.pagination-component{
  display: flex;
  align-items: center;
  gap: 20px;
  
  .pagination-controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .results-text {
      font-weight: 400;
    }
  }
}

.pagination-pages{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  
  button {
    background-color: transparent;
    border: none;
    padding: 0 5px;
    cursor: pointer;
    
    &:hover {
      color: var(--main-color-one);
    }
    
    &.selected {
      border-bottom: 1px solid var(--heading-color);
      height: 28px;
      font-weight: 500;
    }
  }
  
  .pagination-control-pages {
    display: flex;
    align-items: center;
    gap: 1rem;
    
    .pages {
      max-width: 175px;
      overflow: hidden;
      display: flex;
      gap: 10px;
      
      .link-number {
        &.selected {
          font-weight: bold;
          border-bottom: 1px solid black;
        }
      }
    }
    
    .arrow-control {
      color: #DCC6BA;
      cursor: pointer;
      
      &:hover {
        color: var(--main-color-one);
      }
    }
  }
}