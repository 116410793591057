//== Template Main Color
$color-white: #fff;

//== social media colors
$facebook: #3b5999;
$twitter: #55acee;
$dribbble: #ea4c89;
$behance: #131418;
$google-plus: #dd4b39;
$linkedin: #0077b5;
$instagram: #e4405f;
$pinterest: #c8232c;

$text-color: var(--paragraph-color);

//  transition
$transition: all 0.3s ease-in;
$transition-long: all 0.5s ease-in;
$box-shadow: 0px 2px 12px #97a1b21a;
$box-shadow2: 0px 20px 40px rgba(0, 0, 0, 0.12);
