.property-card {
  cursor: pointer;
  border-bottom: 5px solid var(--main-color-two);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  position: relative;
  
  .icon-star {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    color: white;
  }
  
  .thumb {
    width: 100%;
    position: inherit;
    
    &.full-height {
      height: auto;
    }
  }
  
  .single-feature {
    border-radius: 0;
    margin-bottom: 0;
    box-shadow: none;
    
    .details .info-list div svg {
      height: 20px;
    }
    
    .details .info-list {
      font-size: 14px;
    }
  }
  
  .details {
    .title {
      font-size: 20px;
    }
    .second-row {
      font-size: 18px;
    }
  }
  
  .info-list div {
    display: flex;
    align-items: center;
  }
  
}