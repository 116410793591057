.xml-feed {
  .xml-feed-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .items {
    width: 100%;

    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid white;
    }
  }

  .btn-sm {
    height: 30px;
    line-height: 30px;
    font-size: 90%;
  }
  .btn-white {
    &:hover {
      background-color: #d9d9d9;
    }
    .spinner {
      border: 4px solid #dcc5ba66;
      border-left-color: var(--main-color-two);
    }
  }
}
