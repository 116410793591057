/*----------------------------------------------
    ## main search
----------------------------------------------*/
.main-search-area {
  margin-top: -50px;
}
.rld-main-search {
  padding: 50px 30px 35px 30px;
  background: var(--light-bg-color);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  .rld-single-input,
  .rld-single-select .single-select {
    margin-bottom: 15px;
  }
  .btn {
    padding: 0 15px;
  }
  .filter-buttons {
    display: flex;
    gap: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
    }

    .search-button {
      font-weight: 600;
      font-size: 20px;
      flex: 1;
    }
    .clear-button {
      width: auto;
      color: var(--main-color-two);
      border: 2px solid var(--main-color-two);
      background: none;
    }
  }
}
.rld-single-input {
  position: relative;
  input {
    height: 48px;
    line-height: 48px;
    width: 100%;
    padding: 0 20px;
    border: 1px solid #d4d4d4;
    @include transition(all, 0.4s, ease);
    border-radius: 4px;
    font-size: 15px;
    &:hover,
    &:active,
    &:focus {
      border: 1px solid var(--main-color-one);
    }
  }
  textarea {
    height: 120px;
    width: 100%;
    padding: 10px 20px;
    border: 1px solid #d4d4d4;
    @include transition(all, 0.4s, ease);
    border-radius: 4px;
    font-size: 15px;
    &:hover,
    &:active,
    &:focus {
      border: 1px solid var(--main-color-one);
    }
  }
}

.rld-price-slider-wrap {
  .rld-price-slider,
  .rld-size-slider {
    height: 5px;
    background: #fff0e5;
    border: 0;
    margin: 20px 0 0 0;
  }
  .ui-slider-handle {
    height: 24px;
    width: 42px;
    background: var(--main-color-one);
    border: 0;
    text-align: center;
    color: $color-white;
    border-radius: 30px;
    top: -10px;
    font-size: 13px;
    line-height: 25px;
  }
}

/****** banner-search *******/
.rld-banner-search {
  .rld-single-input {
    input {
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
      border: 0;
      padding-right: 140px;
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 48px;
      line-height: 48px;
      border: 0;
      padding: 0 20px;
      font-weight: 600;
      background: var(--main-color-one);
      color: $color-white;
      cursor: pointer;
      border-radius: 0 4px 4px 0;
    }
  }
}

.phone-field {
  height: 48px !important;
  line-height: 48px !important;
  width: 100% !important;
  padding: 0 20px;
  border: 1px solid #d4d4d4;
  background: #f3f3f3 !important;
  @include transition(all, 0.4s, ease);
  border-radius: 4px;
  font-size: 15px;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid var(--main-color-one);
  }
}