/*--------------------------------------------------
    ##Footer
---------------------------------------------------*/
.footer-area {
  background: var(--main-color-one);
  padding-top: 100px;
  z-index: 1;
  position: relative;
  color: white;
  .widget {
    border: 0;
    margin-bottom: 48px;
  }
  .footer-top {
    border-bottom: 1px solid #707070;
    padding-bottom: 48px;
  }
  .footer-logo {
    svg {
      width: 200px;
    }
  }
  .footer-social {
    span {
      margin-right: 20px;
      color: white;
      font-weight: 600;
      i {
        padding-left: 10px;
      }
    }
    .social-icon {
      display: inline-block;
    }
  }
  .footer-bottom {
    padding-top: 50px;
  }
  .copy-right {
    padding: 0 0 44px 0;
    color: rgba(0, 0, 0, 0.8);
    a {
      font-weight: 600;
      @include transition(all, 0.4s, ease);
      &:hover {
        color: var(--main-color-one);
      }
    }
  }
  &.style-two {
    margin-top: 200px;
  }
}

.subscribe-area {
  background: var(--light-bg-color);
  // border: 2px solid var(--main-color-one);
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  // border-radius: 10px;
  padding: 40px 25px 50px 25px;
  margin-bottom: 100px;
  margin-top: -200px;
  position: relative;

  h2 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .rld-single-input {
    display: flex;
  }
  p {
    margin-bottom: 42px;
  }
  input {
    border: 1px solid var(--main-color-one) !important;
    // margin-right: 20px;
    border-radius: 0 !important;
    width: auto;
    flex: 1;
    &::placeholder {
      color: var(--heading-color);
      opacity: 0.6;
    }
  }
  button {
    height: 48px;
    border: 0;
    padding: 0 42px;
    font-weight: 600;
    background: var(--main-color-one);
    color: $color-white;
    cursor: pointer;
    // border-radius: 4px;
  }
  .icon {
    // position: absolute;
    top: -90px;
    text-align: center;
    width: 100%;

    svg {
      width: 50px;
    }
  }
}
