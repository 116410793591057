.comments-container {
  .carousel {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2rem 0;
    
    .control {
      cursor: pointer;
      
      svg {
        color: var(--main-color-one);
        
        &:hover {
          color: var(--main-color-two);
        }
      }
    }
  }
}
