.what-do-you-want-container {
  background-color: var(--main-color-one);
  padding: 4rem 1rem;

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;

    .card {
      background-color: white;
      align-items: center;
      position: relative;
      max-width: 34rem;
      

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -35px;
        border-radius: 100%;
        width: 6rem;
        height: 6rem;

        svg {
          width: 3rem;
        }
      }

      .card-title {
        width: 100%;
        text-align: center;
        border-bottom: 2px solid var(--main-color-two);
        padding-bottom: 10px;

        .title {
          padding-top: 5rem;
        }
      }
      
      .card-content {
        width: 100%;
        padding: 1rem 0 1rem 0;
        text-align: center;
        padding: 1rem 2rem 3rem;

        .description {
          font-size: 21px;
          padding: 0 3rem 1rem 3rem;
          font-weight: 500;
        }
      }
    }
  }
}
