.btn-ghost {
  color: #fff;
  background-color: transparent !important;
  border: 1px solid #fff !important;
  
  &:hover {
    background-color: white !important;
    color: var(--paragraph-color);
  }
}

.btn-default {
  background-color: var(--main-color-one);
}