.main-header {
  transition: transform 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
  
  &.slide {
    position: fixed;
    transform: translateY(0);
  }
  
  &.unslide {
    transform: translateY(-100%);
  }
}
