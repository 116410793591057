/* --------------------------------------------------
	## Pricing list
---------------------------------------------------- */
.single-pricing {
  box-shadow: $box-shadow;
  padding: 40px 15px 40px 15px;
  margin-bottom: 30px;
  border-radius: 10px;
  @include transition(all, 0.5s, ease);
  background: $color-white;
  .thumb {
    &.double-img {
      margin-bottom: 33px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -10px;
        right: 0;
        margin: 0 auto;
        width: 60px;
        height: 60px;
        background: #fff0e1;
        border-radius: 50%;
        z-index: 0;
      }
      img {
        display: inline-block;
        padding-top: 16px;
        padding-left: 16px;
        z-index: 1;
        position: relative;
      }
    }
  }
  .details {
    .title {
      font-weight: 600;
      margin-bottom: 14px;
    }
    .price {
      font-weight: 500;
    }
    h6 {
      margin-bottom: 25px;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        margin-bottom: 5px;
      }
    }
    .btn {
      margin-top: 27px;
      letter-spacing: 0.26px;
    }
  }
  &:hover {
    box-shadow: $box-shadow2;
  }
}
